/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Options, Vue } from 'vue-class-component'
import Api from '@/api/index'
import { SuperVue } from '@/mixins/SuperVue'
import Utils from '@/utils'
import Config from '@/config'

@Options({
  components: {}
})

export default class Quota extends SuperVue {
  // 手写代码
  $refs!: { dialogAForm: any }
  columns = [
    { type: 'index', min_width: 60, align: 'center', title: '序号' },
    { title: '指标名称', key: 'name', align: 'center', min_width: 60 },
    { title: '权重', key: 'weight', align: 'center' },
    { title: '指标类别', key: 'type_name', align: 'center' },
    { title: '创建时间', key: 'created_at', align: 'center' },
    { slot: 'operate', title: '操作', key: 'operate', width: 180, align: 'center', fixed: 'right' }
  ]

  dialogARule = {
    type: [{ require: true, message: '不能为空', trigger: 'change', type: 'number' }],
    parent_id: [{ require: true, message: '不能为空', trigger: 'change', type: 'number' }],
    name: [{ required: true, message: '不能为空', trigger: 'blur' }],
    evaluation_criterion: [{ required: true, message: '不能为空', trigger: 'blur' }],
    weight: [{ required: true, message: '不能为空', trigger: 'blur', type: 'number' }]
  }

  types = [] as any
  // 1--技术价值，2--法律价值，3--推广应用程度，4--经济价值，5--社会价值
  parentTypes = Config.weightsTypes

  buttonShow(name) {
    return Utils.showBtn(name)
  }

  // 获取权重类别
  getTypes() {
    Api.http_getAllType({}).then(res => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.types = res.data.data
      }
    })
  }

  // 打开弹窗
  openDialogA(type: string = 'add', row: any = {}): void {
    this.dialogA.dialog_type = type
    if (type === 'add') {
      this.dialogA.title = '新增指标'
      this.dialogA.evaluation_criterion = ''
      this.dialogA.icon = ''
      this.dialogA.id = 0
      this.dialogA.name = ''
      this.dialogA.type = 5
      this.dialogA.parent_id = 1
      this.dialogA.weight = 0
      this.dialogA.weight_items = []
    } else {
      this.dialogA.title = '编辑指标'
      this.dialogA.id = row.id
      this.dialogA.name = row.name
      this.dialogA.type = row.type_id
      this.dialogA.parent_id = row.parent_id
      this.dialogA.evaluation_criterion = row.evaluation_criterion
      this.dialogA.weight = row.weight
      this.dialogA.weight_items = row.weight_items
    }
    this.dialogA.open = true
  }

  // 提交表单
  submitdialogAForm() {
    this.$refs.dialogAForm.validate(valid => {
      if (valid) {
        this.dialogA.dialog_type === 'add' ? this.addWeight() : this.updateWeight()
      }
    })
  }

  created() {
    this.getTableDataA()
    this.getTypes()
  }

  // 生成代码
  searchData = { name: '', type: 1 };
  pageA = 1;
  pageSizeA = 10;
  weightList = [];
  totalA = 0;
  getTableDataA() {
    const offset = (this.pageA - 1) * this.pageSizeA;
    const postData = {
      name: this.searchData.name,
      type: this.searchData.type,
      offset: offset,
      length: this.pageSizeA
    };
    Api.http_getWeightList(postData).then(res => {
      if (res.code !== '200') {
        console.error('请求失败', res);
      } else {
        this.weightList = res.data.data;
        this.totalA = res.data.total;
      }
    });
  }
  pageChangeA(e) {
    this.pageA = e;
    this.getTableDataA();
  }
  pageSizeChangeA(e) {
    this.pageSizeA = e;
    this.getTableDataA();
  }
  // --------------------------------------
  dialogA = {
    open: false,
    dialog_type: 'add',
    type: 5,
    icon: '无',
    id: 0,
    title: '新增',
    name: '',
    evaluation_criterion: '',
    weight: 0,
    parent_id: '' as any,
    weight_items: [{ weight_id: 0, title: '', score: 0 }]
  };
  dialogA_close(close) {
    close();
    this.dialogA_cancel();
  }
  dialogA_cancel() {
    this.dialogA.open = false;
    this.$refs.dialogAForm.resetFields()
  }

  // --------------------------------------
  addWeight() {
    const postData = {
      title: this.dialogA.title,
      type_id: this.dialogA.type,
      name: this.dialogA.name,
      icon: this.dialogA.icon,
      evaluation_criterion: this.dialogA.evaluation_criterion,
      weight: this.dialogA.weight,
      weight_items: this.dialogA.weight_items,
      parent_id: this.dialogA.parent_id
    };
    Api.http_addWeight(postData).then(res => {
      if (res.code !== '200') {
        console.error('请求失败', res);
      } else {
        this.getTableDataA()
        this.dialogA.open = false
        this.$refs.dialogAForm.resetFields()
        this.$message.success(res.message);
      }
    });
  }
  // --------------------------------------
  updateWeight() {
    const postData = {
      id: this.dialogA.id,
      title: this.dialogA.title,
      name: this.dialogA.name,
      type_id: this.dialogA.type,
      icon: this.dialogA.icon,
      evaluation_criterion: this.dialogA.evaluation_criterion,
      weight: this.dialogA.weight,
      weight_items: this.dialogA.weight_items,
      parent_id: this.dialogA.parent_id
    };
    Api.http_updateWeight(postData).then(res => {
      if (res.code !== '200') {
        console.error('请求失败', res);
      } else {
        this.getTableDataA()
        this.dialogA.open = false
        this.$refs.dialogAForm.resetFields()
        this.$message.success(res.message);
      }
    });
  }
  // --------------------------------------
  delWeight() {
    const postData = {
      id: this.dialogA.id
    };
    Api.http_delWeight(postData).then(res => {
      if (res.code !== '200') {
        console.error('请求失败', res);
      } else {
        this.getTableDataA()
        this.$message.success(res.message);
      }
    });
  }
}
